import React, { useEffect, useState, useMemo } from "react";
import ReactPlayer from "react-player";
import ReactAudioPlayer from 'react-audio-player';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import styled from "styled-components";

import { getVideos, postVideos, toWhisperText, translateToAudio, getTranslatedAudio } from "services/voice.service";

const StyledWrapper = styled.div`
  width: 1200px;
  height: 400px;

  .MuiDataGrid-cellContent {
    white-space: break-spaces;
    text-align: left;
  }

  .MuiDataGrid-cell {
    padding: 10px;
    min-height: 70px !important;
  }

  .MuiDataGrid-row {
    min-height: 70px !important;
  }

  select {
    width: 100%;
  }
`;

const VideoWrapper = styled.div`
  display: inline-block;
  justify-content: center;

  p {
    font-size: 18px;
    font-weight: 600;
  }

  .caption {
    width: 1000px;
    background-color: #ddd;
    padding: 20px;
    text-align: justify;
    margin-top: 20px;
  }

  .ori-video > div {
    margin: auto;
  }
`;

const listPerson = {
  Indonesian: [
    {
      Name: "Ardi - Male",
      ID: "id-ID-ArdiNeural"
    },
    {
      Name: "Cindy - Female",
      ID: "id-ID-Wavenet-A"
    }
  ],
  Vietnamese: [
    {
      Name: "Danh - Male",
      ID: "vi-VN-Standard-D"
    },
    {
      Name: "Bich - Female",
      ID: "vi-VN-Standard-A"
    }
  ]
}

const HomePage = () => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({});
  const [selectedPerson, setSelectedPerson] = useState({});
  const [processPerson, setProcessPerson] = useState("");

  useEffect(() => {
    getVideos().then((res) => {
      setList(res.data);

      const videoLanguage = {};
      const userAI = {};
      res.data?.map((item) => {
        videoLanguage[item._id] = "Indonesian";
        userAI[item._id] = listPerson.Indonesian[0].ID;
        return false;
      })

      setSelectedLanguage(videoLanguage);
      setSelectedPerson(userAI);
    })
  }, []);

  const handleUpload = (e) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("fileVideo", e.target.files[0]);

    postVideos(formData)
    .then((res) => {
      setLoading(false);
      setList([...list, res.data]);
      setSelectedLanguage(() => {
        return {
          ...selectedLanguage,
          [res.data?._id]: "Indonesian"
        }
      })
      setSelectedPerson(() => {
        return {
          ...selectedPerson,
          [res.data?._id]: listPerson.Indonesian[0].ID
        }
      })
    }).catch((err) => {
      setLoading(false);
      window.alert("error on upload");
    })
  }

  const handleConvertToText = (video) => {
    setLoadingVideo(true);
    setProcessPerson(selectedPerson[video.row?._id]);
    const params = {
      selectedLangauge: selectedLanguage[video.row?._id],
      selectedPerson: selectedPerson[video.row?._id]
    }

    // got audio id
    const existAudio = video.row.translated.find(x => x.personId === params.selectedPerson);
    if (existAudio) {
      fetchAudio(video.row._id, existAudio.playhtID);
    } else {
      if (video.row.originalCaption) {
        translateToAudio(video.row._id, params)
        .then((res2) => {
          if (res2?.data?.result?.data?.error === true) {
            alert(res2?.data?.result?.data?.errorMessage ?? "Error on conversion");
          }
          return getTranslatedAudio(video.row._id, res2.data?.result?.data?.transcriptionId);
        })
        .then((res3) => {
          console.log(res3)
          setSelectedVideo({
            ...res3.data?.video,
            audio: res3.data?.result?.data?.audioUrl
          });
          setLoadingVideo(false);
        });
      } else {
        toWhisperText(video.row._id)
        .then((res) => {
          if (res.data) {
            setList((prev) => {
              const updatedVideo = prev.find(x => x._id === video.row._id);
              if (updatedVideo) {
                updatedVideo.originalCaption = res.data;
              }
              
              return [...prev];
            })
          }
          return translateToAudio(video.row._id, params);
        })
        .then((res2) => {
          if (res2?.data?.result?.data?.error === true) {
            alert(res2?.data?.result?.data?.errorMessage ?? "Error on conversion");
          }
          return getTranslatedAudio(video.row._id, res2.data?.result?.data?.transcriptionId);
        })
        .then((res3) => {
          setSelectedVideo({
            ...res3.data?.video,
            audio: res3.data?.result?.data?.audioUrl
          });
          setLoadingVideo(false);
        });
      }
    }
  }

  const fetchAudio = (videoId, transcriptionId) => {
    getTranslatedAudio(videoId, transcriptionId)
    .then((res) => {
      if (res?.data?.result?.data?.error === true) {
        alert(res?.data?.result?.data?.errorMessage ?? "Error on conversion");
      }
      setSelectedVideo({
        ...res.data?.video,
        audio: res.data?.result?.data?.audioUrl
      });
      setLoadingVideo(false);
    })
  }

  const columns = useMemo(() => {
    return [
      { field: "index", headerName: "Index", width: 50 },
      { field: "key", headerName: "Key", width: 300 },
      { field: "link", headerName: "Link", width: 520, },
      { 
        field: "language", 
        headerName: "Language", 
        width: 120, 
        renderCell: (video) => (
          <select onChange={(e) => {
              setSelectedVideo(null);
              setSelectedLanguage(() => {
                return {
                  ...selectedLanguage,
                  [video.row?._id]: e.target.value
                }
              });
              setSelectedPerson(() => {
                return {
                  ...selectedPerson,
                  [video.row?._id]: listPerson[e.target.value][0].ID
                }
              });
            }}
            value={selectedLanguage[video.row?._id]}
          >
            <option value="Indonesian">Indonesian</option>
            <option value="Vietnamese">Vietnamese</option>
          </select>
        )
      },
      { 
        field: "person", 
        headerName: "Person", 
        width: 120, 
        renderCell: (video) => (
          <select onChange={(e) => {
              setSelectedVideo(null);
              setSelectedPerson(() => {
                return {
                  ...selectedPerson,
                  [video.row?._id]: e.target.value
                }
              });
            }}
            value={selectedPerson[video.row?._id]}
          >
            {
              listPerson[selectedLanguage[video.row?._id]]?.map((item, index) => (
                <option key={index} value={item.ID}>{item.Name}</option>
              ))
            }
          </select>
        )
      },
      { 
        field: "action", 
        headerName: "", 
        width: 80, 
        renderCell: (video) => (
          <div>
            <span 
              style={{color: "red", textAlign: "center", width: "100%", cursor: "pointer"}}
              onClick={() => handleConvertToText(video)}
            >
              Process
            </span>
          </div>
        )
      },
    ]
  }, [selectedLanguage, selectedPerson]);

  const rows = useMemo(() => {
    return list.map((item, index) => (
      {
        index: index + 1,
        ...item,
        id: index
      }
    ))
  }, [list]);

  const getTranslatedCaption = () => {
    const result = selectedVideo?.translated?.find(x => x.personId === processPerson);
    if (result === null) {
      return "";
    }

    return result.caption?.text?.replaceAll("<br>", "");
  }

  const getTranslatedAudioId = () => {
    const result = selectedVideo?.translated?.find(x => x.personId === processPerson);
    if (result === null) {
      return "";
    }

    return result.playhtID;
  }

  return (
    <div className="App" style={{padding: "50px"}}>
      <Button variant="contained" component="label" onChange={e => handleUpload(e)}>
        Upload
        <input hidden multiple type="file" />
      </Button>

      <div style={{marginTop: "50px"}}>
        <p style={{fontWeight: "bold", fontSize: "20px"}}>List Videos</p>
        <div style={{display: "flex", justifyContent: "center"}}>
          {
            loading ? (
              <CircularProgress />
            ) : (
              <StyledWrapper>
                <DataGrid
                  rows={rows}
                  columns={columns}
                />
              </StyledWrapper>
            )
          }
        </div>
      </div>

      {
        loadingVideo === false ? (
          selectedVideo !== null && (
            <VideoWrapper style={{marginTop: "50px"}}>
              <div className="ori-video">
                <p style={{fontSize: "26px"}}>Result :</p>
                <p>Original Transcription</p>
                <ReactPlayer url={selectedVideo.link} controls={true} />
                <div className="caption" style={{marginTop: "35px"}}>
                  <span>{selectedVideo.originalCaption?.text}</span>
                </div>
              </div>
              <div style={{marginTop: "35px"}}>
                <p>Translated Transcription</p>
                <div style={{marginBottom: "20px"}}>
                  <div>If audio is not reloaded, please click this button to refetch</div>
                  <button onClick={() => fetchAudio(selectedVideo._id, getTranslatedAudioId())} style={{marginTop: "10px"}}>Refetch Audio</button>
                </div>
                <ReactAudioPlayer
                  src={selectedVideo.audio}
                  controls
                />
                <div className="caption">
                  <span>{getTranslatedCaption()}</span>
                </div>
              </div>
            </VideoWrapper>
          )
        ) : (
          <VideoWrapper style={{marginTop: "50px"}}>
            <CircularProgress />
          </VideoWrapper>
        )
      }
    </div>
  );
}

export default HomePage;
