import React from 'react';

import AppRouter from "routes/app.route";
import './index.css';

const App = () => {
  return (
    <div>
      <AppRouter />
    </div>
  );
}

export default App;
