import axiosReq from "utils/axiosConfig";

export const getVideos = async payload => {
  try {
    const res = await axiosReq.get(`/api/videos`, payload);
    return res;
  } catch(err) {
    console.log(err)
  }
}

export const toWhisperText = async (id) => {
  try {
    const res = await axiosReq.put(`/api/videos/whisper/${id}`, {});
    return res;
  } catch(err) {
    console.log(err)
  }
}

export const translateToAudio = async (id, payload) => {
  try {
    const res = await axiosReq.put(`/api/videos/playht/translate/${id}`, payload);
    return res;
  } catch(err) {
    console.log(err)
  }
}

export const getTranslatedAudio = async (videoId, audioId) => {
  try {
    const res = await axiosReq.get(`/api/videos/playht/translate/${videoId}/${audioId}`);
    return res;
  } catch(err) {
    console.log(err)
  }
}


export const postVideos = async payload => {
  try {
    const res = await axiosReq.post(`/api/videos`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return res;
  } catch(err) {
    console.log(err)
  }
}